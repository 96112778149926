import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    <div
      className={`w-full h-[70vh] svgHexagonInverso bg-cover bg-center bg-no-repeat relative before:absolute ${bgimg ? 'before:bg-[#0000008a]' : 'before:bg-[#0e6e31]'} before:w-full before:h-full`}
      style={
        bgimg ?
          {
            backgroundImage: `url("${bgimg}")`,
            backgroundAttachment: 'fixed'
          }
          : null
      }
    >
      <div className="flex flex-col  h-full pb-20 pt-0 md:pt-20 md:pb-0 justify-end md:justify-center items-center relative">
        <h1 className="text-white">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Home - {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div>
    </div>
  );
};

export default TransparentHeader;
